import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import anime from 'animejs';
// @ts-ignore
import HugloLoadingAnimation from '../assets/animations/huglo_loading.json';

const style = require('./Loading.module.scss');

const rollingSentences = [
  'Comparing plans...',
  'Assessing all retailers...',
  'Firing up the engine...',
  'Sorting the ACs from the DCs...',
  'Turning on all the lights...',
  'Converting kWh to mWh...',
];

const animationOptions = {
  loop: true,
  autoplay: true,
  animationData: HugloLoadingAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Loading = () => {
  const [sentenceIndex, setSentenceIndex] = useState(0);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    const nextSentenceIndex =
      sentenceIndex !== rollingSentences.length - 1 ? sentenceIndex + 1 : 0;

    const t = anime.timeline({
      easing: 'linear',
      duration: 400,
      complete() {
        setSentenceIndex(nextSentenceIndex);
        // t.restart();
      },
    });

    t.add(
      {
        targets: `#sentence-${sentenceIndex}`,
        opacity: [1, 0],
      },
      1200
    );
    t.add({
      targets: `#sentence-${nextSentenceIndex}`,
      opacity: [0, 1],
    });
  }, [sentenceIndex]);

  return (
    <div className={`container ${style.container}`}>
      <Lottie
        options={animationOptions}
        style={{ width: '300px', margin: 0 }}
        isClickToPauseDisabled
      />
      <div className={style.rollingTextWrapper}>
        {rollingSentences.map((sentence, index) => (
          <p className={style.rollingText} id={`sentence-${index}`}>
            {sentence}
          </p>
        ))}
      </div>
    </div>
  );
};

export default Loading;
